@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  margin: 0;
  padding: 0;
}

.App {
  background-color: #191d2b;
  height: 100%;
  width: 100%;
}
a{
  text-decoration: none;
  color: #fff;
}