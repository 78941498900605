.Contact {
  padding: 3rem 0rem;
  color: #fff;
  width: 100%;
  min-height: 100vh;
}

.Contact .title {
  line-height: 0rem;
  text-transform: uppercase;
  margin: 0% 10%;
  padding: 3rem;
}
.Contact .bg-text {
  text-align: center;
  font-size: 5.5rem;
  color: #192437;
  transition: all 0.4s ease-in-out;
  z-index: -1;
  font-weight: 800;
}

.Contact .main-text {
  text-align: center;
  width: 100%;
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
}

.Contact .main-text span {
  color: #27ae60;
}

/************************************************
            //CONTACT
************************************************/

.Total-contact {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin: 8% 15%;
}

.Left-contact {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Left-contact .contact-head {
  color: #dbe1e8;
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
}

.Left-contact .contact-subhead {
  text-transform: capitalize;
  font-size: 1.2rem;
}

.Left-contact .details {
  display: flex;
  flex-direction: column;
  line-height: 2.25rem;
  width: 100%;
  font-size: 1.2rem;
}

.Left-contact .details .detail {
  display: flex;
  gap: 2rem;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.Left-contact .details .detail > i {
  margin-right: 1rem;
}

.Left-contact .details .detail > div {
  color: #b2becd;
}

.Left-contact .icons {
  display: flex;
  gap: 1.5rem;
  margin: 1rem 0rem;
}
.Left-contact .icon {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #2a2e35;
  cursor: pointer;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 0.4rem;
  transition: all 0.4s ease-in-out;
}

.Left-contact .icon i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}

.Left-contact .icon:hover {
  background-color: #27ae60;
}

.right-contact {
  width: 50%;
  line-height: 4.5rem;
  margin-left: 1rem;
}
.right-contact .input-control {
  margin: 1.5rem 0;
}

.right-contact .input-control input,
.input-control textarea {
  border-radius: 30px;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  padding: 0.8rem 1.1rem;
  outline: none;
  border: none;
  background-color: #2a2e35;
  width: 100%;
  color: #fff;
  resize: none;
}

.input-control textarea {
  margin-top: 1rem;
  height: 150px;
}

.right-contact button {
  border-radius: 30px;
  height: 55px;
  width: 15em;
  color: white;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  padding-top: 1rem;
  border: 1px solid #27ae60;
  align-items: center;

  background: linear-gradient(to right, transparent 50%, #27ae60 50%);
  background-color: transparent;
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.5s ease-out;
}

.right-contact button:hover {
  background-position: right bottom;
}

.right-contact button i {
  background-color: #27ae60;
  color: #fff;
  border-radius: 50%;
  padding: 1rem;
  float: right;
  margin-top: -1rem;
}

@media screen and (max-width: 750px) {
  .Contact .bg-text {
    font-size: 4rem;
  }
  .Contact .main-text {
    font-size: 3rem;
  }
}
@media screen and (max-width: 550px) {
  .Contact .bg-text {
    font-size: 3.25rem;
  }
  .Contact .main-text {
    font-size: 2.5rem;
  }
  .Contact .title {
    margin: 0 5%;
  }
}

@media screen and (max-width: 400px) {
  .Contact .bg-text {
    font-size: 2.25rem;
  }
  .Contact .main-text {
    font-size: 1.8rem;
  }
  .Contact .title {
    margin: 0 2.5%;
  }
  .right-contact button {
    width: 10em;
  }
}
